
@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/afi.scss";
@import "_mixins/base.scss";

.main-slider{
    width:100%;
    position:relative;
    &-item{
        @include flex-block;
        @include justify-content(center);
        @include align-items(center);
        width:100%;
        background-position:top center !important;
        background-repeat:no-repeat !important;
        @include background-size(cover);
        &-title{
            font-size: 34px;
            line-height: 40px;
            text-align:center;
						padding: 20px 15px;
            color: $color-white;
						@include media(md) {
								@include font-size(48px);
								line-height: 58px;
						}
						@include media(lg) {
								@include font-size(62px);
								line-height: 72px;
								padding: 30px 20px;
								text-align: left;
						}
						@include media(xxl) {
								@include font-size(72px);
								line-height: 80px;
						}
        }
        &-inner{
            @include flex-block;
            @include calc("min-height", "100vh - 50px -" $header-w-small);
						@include flex-flow(column);
						@include justify-content(center);
						@include align-items(center);
						padding: 40px 0;
						@include media(md) {
								@include calc("min-height", "100vh - 60px -" $header-w-small);
						}
            @include media(lg){
								@include calc("min-height", "100vh - 70px -" $header-w-large);
								@include flex-flow(column);
								@include justify-content(space-between);
								@include align-items(flex-start);
								padding: 0;
            }
        }
    }
    .slick-dots{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 15px;
        text-align: center;
        padding:0;
        margin:0;
        li{
            display: inline-block;
            vertical-align: middle;
            margin: 0 5px;
            width: 13px;
            height: 13px;
            cursor: pointer;
            background: transparent;
            @include border-radius(50%);
            @include animate();
            background: $color-white;
            button{
                display: none;
            }
            &.slick-active{
                background: $color-orange;
            }
        }
    }
}

.hp-card {
		&-container {
				margin-top: 30px;
				@include media(md) {
						margin-top: 40px;
				}
				h2 {
						margin-bottom: 0;
						margin-right: 20px;
				}
				.hp-card-slider {
						overflow: hidden;
				}
				.slick-list {
						margin-left: -15px;
						margin-right: -15px;
						@include media(lg) {
								margin-left: -20px;
								margin-right: -20px;
						}
						.slick-slide {
								padding-left: 15px;
								padding-right: 15px;
								overflow: hidden;
								@include media(lg) {
										padding-left: 20px;
									  padding-right: 20px;
								}
						}
				}
				.slick-arrow {
					position: absolute;
					top: 50%;
					width: 20px;
					height: 40px;
					transform: translateY(-50%);
					@include font-size(0px);
					cursor: pointer;
					z-index: 99;
					border: 0;
					background: transparent;
					&:before {
							@include icomoon;
							content: "\e920";
							position: relative;
							display: inline-block;
							color: rgba($color-black, .4);
							font-weight: 600;
							@include font-size(40px);
							width: 20px;
							height: 40px;
							border-radius: 40px;
							@include transition(200ms);
							@include flex-block;
							@include justify-content(center);
							@include align-items(center);
					}
					&:focus,
					&:hover {
							outline: 0;
							border: 0;
							&:before {
									color: rgba($color-black, 1);
							}
					}
				}
				.slick-prev {
						left: 0;
				}
				.slick-next {
						right: 13px;
						&:before {
							content: "\e91f";
						}
				}
				.slick-disabled {
						&:before {
								opacity: 0;
						}
				}
		}
		&-title-box {
				margin-bottom: 30px;
				@include media(md) {
						margin-bottom: 40px;
				}
		}
}

.news-items {
		padding-bottom: 20px;
		overflow: hidden;
}



.devider-title {
		margin: 15px 0;
		@include media(lg) {
				margin: 20px 0;
		}
		h2 {
				font-family: 'Zirkel-Black';
				color: $color-black;
				margin: 0;
		}
}

.devider-title-link{
    text-decoration: none;
    color:#000;
    display:inline-block;
    @include flex-block;
    font-size: 16px;
    @include align-items(center);
    &:after{
        content:"\e908";
        background:transparent;
        @include border-radius(50%);
        border:1px solid #000;
        @include transition;
        width: 30px;
        height: 30px;
        @include font-size(30px);
        line-height:29px;
				margin-left: 10px;
    }
    &:focus,
    &:hover{
        color: $color-black;
        &:after{
            background: $color-secondary;
            color:#fff;
            border:1px solid $color-secondary;
        }
    }
    span{
        display:none;
    }
    @include media(xs){
				span {
						display: inline-block;
						margin:0 0 0 5px;
				}
				&:after {
						margin:2px 0 0 10px;
				}
    }
    @include media(md){
        font-size: 20px;
        &:after{
            width: 30px;
            height: 30px;
            @include font-size(30px);
            line-height:29px;
        }
    }
    @include media(xl){
        &:after{
            width:34px;
            height:34px;
            @include font-size(34px);
            line-height:33px;
						position: relative;
						top: 2px;
        }
    }
    @include media(xxl){
        font-size: 22px;
        line-height: 1;
        color: $color-black;
    }
}



.hp-project {
		&-item {
				height: 350px !important;
				background-position: center !important;
				background-size: cover !important;
				width: 100%;
				display: flex !important;
				@include flex-flow(column);
				@include justify-content(flex-end);
				@include media(md) {
						height: 400px !important;
				}
				@include media(lg) {
						height: 440px !important;
				}
				.h2 {
						color: $color-white;
						padding: 0 0 10px 20px;
						@include transition;
						@include media(lg) {
								padding: 0 0 20px 30px;
						}
				}
				&:hover,
				&:focus {
						.h2 {
								color: $color-link;
						}
				}
		}
}



.top-box {
		&-image {
				@include calc("min-height", "100vh - 120px - " $header-w-small);
				@include flex-block;
				@include align-items(center);
				@include justify-content(center);
				padding: 50px 15px;
				border-bottom: 2px solid #fff;
				background-position: center;
				background-size: cover;
				color: #fff !important;
				text-align: center;
				margin: 0;
				@include media(md) {
						@include calc("min-height", "100vh - 180px - " $header-w-small);
				}
				@include media(lg) {
						@include calc("min-height", "100vh - 210px - " $header-w-large);
						border-bottom: 4px solid #fff;;
				}
		}
		&-nav {
				height: 120px;
				@include media(md) {
						height: 180px;
				}
				@include media(lg) {
						height: 210px;
				}
				&-item {
						background-size: cover;
						background-position: center;
						background-repeat: no-repeat;
						padding: 10px 15px;
						height: 100%;
						border-right: 2px solid #fff;
						@include media(md) {
								padding: 15px 20px;
								border-right: 4px solid #fff;
						}
						&.theme-light {
								color: #fff !important;
						}
						&.theme-dark {
								color: $color-primary !important;
						}
				}
				& > .slick-list {
						height: 100%;
						& > .slick-track {
								height: 100%;
						}
				}
		}
}

.hp-miss {
		&-title {
				height: 50px;
				@include media(md) {
						height: 60px;
				}
				@include media(lg) {
						height: 70px;
				}
				h2 {
						height: 100%;
						width: 100%;
						margin: 0;
						padding: 0;
						@include flex-block;
						@include align-items(center);
						@include justify-content(flex-start);
				}
		}
}
