
@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/afi.scss";
@import "_mixins/base.scss";

.cms-bootstrap [class^="icon-"]:before, .cms-bootstrap [class*=" icon-"]:before {
    font-family: 'Core-icons' !important;
}

*:before,
*:after{
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@include font-face-otf("Produkt-Light", "de400772-3f32-40e1-8683-25dad71656cd/Produkt-Light.aspx", 300);
@include font-face-otf2("Zirkel-Regular", "/JTE/media/system/font/Zirkel-Regular", 400);
@include font-face-otf2("Zirkel-Black", "/JTE/media/system/font/Zirkel-Black", 900);
@include font-face-otf2("Zirkel-Bold", "/JTE/media/system/font/Zirkel-Bold", 700);


body,
input,
select,
textarea {
    font-family: 'Produkt-Light';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
		font-size: 16px;
}

body {
	background-position: center;
	background-size: cover;
	background-attachment: fixed;
	background-color: #000;
}

.main-content-product {
		table {
				width: 100%;
				margin-top: 30px;
				margin-bottom: 15px;
				td {
						padding: 3px 5px;
				}
		}
}

.h1, .h2, .h3, .h4, .h5, .h6{
    display: block;
}

h1,.h1 {
		font-family: 'Produkt-Light';
    @include font-size-with-line(32px);

    @include media(sm){
        @include font-size-with-line(34px);
    }
    @include media(md){
        @include font-size-with-line(36px);
    }
    @include media(lg){
        @include font-size-with-line(38px);
    }
    @include media(xl){
        @include font-size-with-line(40px);
    }
		&.bold {
				font-family: 'Zirkel-Black';
				color: $color-black;
				@include font-size-with-line(44px);
				line-height: 36px;

				@include media(md){
						@include font-size-with-line(66px);
						line-height: 60px;
				}
				@include media(lg){
						@include font-size-with-line(88px);
						line-height: 105px;
				}

		}
}

h2,.h2 {
		@include font-size-with-line(24px);

		@include media(sm){
				@include font-size-with-line(28px);
		}
		@include media(md){
				@include font-size-with-line(30px);
		}
		@include media(lg){
				@include font-size-with-line(32px);
		}
		@include media(xl){
				@include font-size-with-line(36px);
		}
		&.bold {
				font-family: 'Zirkel-Black';
				@include font-size-with-line(24px);
				@include media(sm){
						@include font-size-with-line(28px);
				}
				@include media(md){
						@include font-size-with-line(30px);
				}
				@include media(lg){
						@include font-size-with-line(32px);
				}
				@include media(xl){
						@include font-size-with-line(36px);
				}

		}
}

h3,.h3 {
    @include font-size-with-line(22px);
		@include media(md){
        @include font-size-with-line(26px);
    }
    @include media(lg){
        @include font-size-with-line(28px);
    }
}

h4,.h4 {
    @include font-size-with-line(18px);
		@include media(md){
        @include font-size-with-line(20px);
    }
    @include media(lg){
        @include font-size-with-line(22px);
    }
}

@each $name, $color in $password-strength{
		.password-strength-#{$name} {
				color: $color;
		}
}




strong,
.bold,
b{
    font-weight:600;
}

.semibold{
    font-weight:600;
}

a {
		color: #000;
		text-decoration: none !important;
		&:hover,
		&:focus {
				color: $color-link;
		}
}

.title-line {
  padding-bottom: 5px;
  border-bottom: 2px solid $color-black;
  margin-bottom: 20px;
}

.title-line-slim {
  font-weight: bold;
  padding-bottom: 3px;
  border-bottom: 1px solid $color-black;
  margin-bottom: 5px;
}

.title-line-respons {
  padding-bottom: 0px;
  border-bottom: 1px solid $color-black;
  margin-bottom: 3px;

  @include media(xs) {
    border-bottom: 2px solid $color-black;
    margin-bottom: 5px;
  }
}

.square-button,
.square-button-bw {
  display: inline-block;
  padding: 5px;
  margin: 0 2px;
  margin-top: 15px;
  position: relative;
  border: 1px solid $color-black;
  @include animate("background-color, color");
  @include font-size(15px);
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
  color: $color-black;
  text-align: center;
  &:focus,
  &:hover {
    color: $color-white !important;
    background: $color-link;
		border: 1px solid $color-link;
    text-decoration: none !important;
  }
}

.square-button-bw {
  &:focus,
  &:hover {
    color: $color-white !important;
    background: $color-black;
  }
}

.big-button-bw,
.big-button-bw-small,
.arrow-down-link,
.btn-show-more {
	width: auto;
	padding: 0 15px;
	min-height: $btn-height;
	line-height: $btn-height - ($btn-border-width*2);
	vertical-align: middle;
	display: inline-block;
	font-family: 'Zirkel-Bold';
	font-size: 18px;
	border: 2px solid $btn-border;
	color: $color-black;
	background: transparent;
	cursor: pointer;
	text-decoration: none;
	outline: none;
	text-align: center;
	@include animate("background, color");
	&:hover,
  &:focus {
		color: $color-white !important;
		background: $color-link;
    text-decoration: none;
		border: 2px solid $color-link;
	}
}

.big-button-bw-small,
.arrow-down-link {
	font-size: 15px;
  padding: 0 10px;

  @include media (md) {
    @include font-size(18px);
    padding: 0 20px;
  }
}

.btn{
  display: inline-block;
  @include border-radius(0);
  border: none;
  padding:10px 15px;
  @include box-shadow(none);
	white-space: normal !important;
	max-width: 100%;
	@include media(md) {
			width: 250px;
	}
	@include media(xl) {
			width: 335px;
	}
  &:focus,
  &:hover{
      text-decoration: none;
      @include box-shadow(none);
  }
}

.btn-primary,
input[type=submit] {
	  background: #fff;
	  color: #000;
		border: 2px solid #000;
		@include transition;
		padding: 10px 25px;
		font-weight: 400;
		@include font-size(16px);
		cursor: pointer;
		color: $color-black;
	  &:focus,
	  &:hover{
	      text-decoration: none;
	      background: $color-link;
	      color: #fff;
				border: 2px solid $color-link;
	  }
		&.btn-big {
				@include media(lg) {
						padding: 18px 55px;
				}
		}
}

.btn-close {
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid $color-white !important;
  color: $color-white !important;
  text-shadow: 0;
  opacity: 1;
  @include font-size(15px);
  @include transition(100ms);

  &:hover,
  &:focus {
    color: $color-white !important;
    background-color: $color-white;
  }
}

.ErrorLabel,
.EditingFormErrorLabel {
	position: relative;
	display: block;
	width: 100%;
	background-color: $color-secondary;
    color: #fff;
}


@each $value in $px-values {
	.pt-#{$value} {
    padding-top: #{$value}px;
	}

  .pb-#{$value} {
      padding-bottom: #{$value}px;
  }

  .mt-#{$value} {
    margin-top: #{$value}px;
  }

  .mb-#{$value} {
      margin-bottom: #{$value}px;
  }
}
