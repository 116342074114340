
@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/afi.scss";
@import "_mixins/base.scss";


.main-content {
	width: 100%;
	margin-top: 20px;

	@include media (md) {
		margin-top: 0px;
	}

  .products-pic {
		max-width: 100%;
		margin-bottom: 20px;
	}

	.products-text {
		margin-bottom: 20px;
	}
}


.products-mobile-menu {
	display: none;
	margin-top: 10px;
  @include font-size(16px);

	&:hover {
		color: $color-base !important;
		background: $btn-bg;
	}

	&:after{
		display: inline-block;
		vertical-align: middle;
    @include icomoon();
		content: "\e903";
		margin-left: 5px;
		margin-top: -3px;
    @include font-size(35px);
		@include animate(transform);
	}

	&.active{
		&:after{
			@include rotate(180deg);
		}
	}

	@include media(xxs, sm){
		display: block;
	}
}


.products-menu {
  width: 100.25%;
  @include list-reset();
  margin-top: 20px;

  @include media(md) {
    display: block !important;
  }

  @include media(lg) {
    margin-top: 0px;
  }

  .products-menu-item {

    & > a {
      display: block;
      text-decoration: none;
      @include font-size(16px);
      line-height: 17px;
      color: $color-black;
      font-weight: 600;
      padding: 5px 5px;
      @include animate("background-color, color");

      @include media(md) {
        @include font-size(14px);
      }

      @include media(lg) {
        @include font-size(16px);
        font-weight: 800;
        padding: 5px 10px;
      }

      @include media(xl) {
        @include font-size(18px);
        line-height: 20px;
      }

      &:hover,
      &:focus {
        background-color: $color-primary;
      }
    }

    &.active > a {
      background-color: $color-primary;

    }

		&.active .products-submenu {
			display: block;
    }

    .products-submenu {
      width: 100%;
      @include list-reset();
      background-color: $color-secondary-light;
      padding: 0 10px;
      margin-bottom: 10px;
      text-decoration: none;
			display: none;

      .products-submenu-item {
        & > a {
          position: relative;
          display: block;
          padding: 7px 0 7px 10px;
          color: $color-black;
          @include font-size(16px);
          line-height: 17px;
          text-decoration: none;

          @include media(md) {
            padding: 5px 0 5px 10px;
            @include font-size(13px);
          }

          @include media(lg) {
            @include font-size(16px);
          }

          @include media(xl) {
            @include font-size(18px);
            line-height: 20px;
          }

          &:before{
    				display: block;
    				content: "";
    				width: 4px;
            height: 4px;
    				background: $color-base;
    				@include border-radius(10px);
    				position: absolute;
    				left: 0 ;
    				top: 14px;
    			}

          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }

        .active {
          font-weight: 800;
        }
      }
    }
  }

}

.products-list,
.content-list {

  .products-item-card {
    display: block;
    width: 100%;
    margin-top: 10px;
    background-color: $color-secondary-light;
    border: 1px solid $color-secondary-light;
    cursor: pointer;
    color: $color-black;
	  text-decoration: none;

    @include media(sm) {
      @include flex-block();
      @include flex-flow();
      @include justify-content(space-between);
      height: 140px;
    }

    @include media(sm, sm) {
      border: 7px solid $color-secondary-light;
      height: 160px;
    }

    @include breakpointhelper(545px, 650px) {
      border: 1px solid $color-secondary-light;
      height: 140px;
    }


    @include media(lg) {
      border: 7px solid $color-secondary-light;
      height: 158px;
    }

     &:hover,
     &:focus {
       background-color: $color-primary;
       border-color: $color-primary;
			 text-decoration: none !important;

       .products-item-card-content {
         &:before {
          background-color: $color-black;
         }

         &:after {
          color: $color-primary;
         }
       }
     }

    &-img {
      width: 100%;
      height: 0;
      padding-bottom: 80%;
      background-color: $color-white;
      overflow: hidden;
      position: relative;

      @include media(sm) {
        width: 48.5%;
        height: 100%;
        padding-bottom: 0;
      }

      img {
        @include cover-image();
      }
    }

    &-content {
      width: 100%;
      position: relative;

      @include media(sm) {
        width: 48.5%;
        padding: 5px 10px 10px 0;
      }

      .products-item-title {
    		font-size: 15px;
    		line-height: 26px;
    		max-height: 26px;
    		overflow: hidden;
    		font-weight: 600;

        @include media(xxs, xs) {
    		  text-decoration: underline;
          margin: 0;
          padding: 0 5px;
        }

        @include media(sm) {
    		  max-height: 52px;
          line-height: 26px;
          font-size: 18px;
        	margin-bottom: 5px;
          background-image: linear-gradient(#000 2px, transparent 2px);
      		background-size: 100% 26px;
      		background-position: 0 23px;
        }

        @include media(md, md) {
          font-size: 15px;
          line-height: 20px;
          max-height: 40px;
      		background-size: 100% 20px;
          background-position: 0 18px;
        }

        @include media(lg) {
      	  max-height: 52px;
          font-size: 18px;
          line-height: 26px;
    		  font-weight: 800;
        }

    		a {
    			display: block;
    			color: $color-base;
    			text-decoration: none;
    		}
      }

      .products-item-perex {
        @include count-of-lines(18, 2);
        display: none;

        @include media(sm) {
          display: block;
        }
      }

      &:before {
        content: "";
        position: absolute;
        display: none;
        bottom: 13px;
        left: 2px;
        width: 27px;
        height: 27px;
        @include border-radius(27px);
        background-color: transparent;
        @include transition(100ms);

        @include media(lg) {
          display: block;
        }
      }

      &:after {
        content: "\e908";
        position: absolute;
        display: none;
        @include font-size(30px);
        @include transition(100ms);

        @include media(sm) {
          display: block;
          bottom: 0px;
          left: -2px;
        }

        @include media(sm, md) {
          color: $color-black !important;
        }
        @include media(lg) {
          bottom: 12px;
          left: 0px;
        }
      }
    }
  }
}

.button-show-products {
  margin-top: 20px;
  margin-bottom: 30px;
}
