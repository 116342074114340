@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/afi.scss";
@import "_mixins/base.scss";

.modal {
  background-color: rgba($color-black, .7);
  color: $color-black;
  padding-right: 0 !important;
  .modal-dialog {
    position: relative;
    @include flex-block();
    @include flex-direction(row);
    @include justify-content(center);
    margin: 10px auto;
    width: 100%;
    max-width: 1250px;
		@include media(md) {
				margin-top: 50px;
		}
		@include media(xxl) {
				margin-top: 100px;
		}
    .modal-content {
				border: 2px solid $color-black;
				border-radius: 0;
    }
  	.modal-header {
        .close {
          color: $color-red;
					position: relative;
					font-size: 0;
					position: absolute;
					top: 15px;
					right: 15px;
					&:after {
							content: "\e921";
							@include icomoon();
							@include font-size(15px);
							display: inline-block;
							color: $color-primary;
							@include transition(100ms);
							cursor: pointer;
							color: $color-red;
							font-weight: 800;

					}
          &:hover,
          &:focus {
							&:after {
	            	color: darken($color-red, 10);
							}
          }
        }
    }
    .modal-body,
    .modal-footer {
    }

    .modal-body{
        text-align: left;
        .modal-perex {
          margin: 10px 0 30px;
          text-align: left;
          @include font-size(14px);
          line-height: 18px;
          font-weight: 600;
          @include media(sm) {
            margin-bottom: 30px;
          }
        }

			}
      .modal-footer {
        display: block;
        margin-top: 10px;

        .contact-submit-cont {
          width: 100%;
          margin: 0;

          .contact-submit-text {
            @include font-size(12px);
            color: #999;
            text-align: left;
            margin-bottom: 5px;

            @include media(sm) {
              margin-bottom: 10px;
            }

            @include media(md) {
              @include font-size(13px);
            }

            @include media(lg) {
              margin-bottom: 18px;
            }
          }

          input[type="submit"] {

        }
      }
    }
  }

  .ErrorLabel,
  .EditingFormErrorLabel {
    position: relative;
    display: block;
    width: 100%;
    margin: 0px 0px;
    padding-left: 15px;
    background-color: $color-primary;
  }
  .ErrorLabel {
      margin: 20px 0px ;
  }
}



.field-required {
  &:after {
    content: "*";
    color: #ff5445;
  }
}

#modal-contact {
		.modal-header {
				padding-bottom: 0px;
				h2 {
						display: block;
						width: 100%;
						border-bottom: 1px solid $color-black;
						padding-bottom: 5px;
						@include media(md) {
								padding-bottom: 10px;
						}
				}
		}
		.modal-body {
				padding-top: 0px;
		}
}

#modal-sign-up {
	.modal-body{
			text-align: left;
			.modal-perex {
				margin: 10px 0 30px;
				text-align: left;
				@include font-size(14px);
				line-height: 18px;
				font-weight: 600;
				@include media(sm) {
					margin-bottom: 30px;
				}
			}
			label[id*=AFIUserCountry] {
					display: none;
			}
			input[type=text],
			input[type=password] {
					background-color: $color-white;
					width: 100%;
					padding: 8px 10px;
					height: 38px;
					color: $color-black;
					margin-bottom: 15px;
					border-radius: 0;
					position: relative;
					-webkit-appearance: none;
					-moz-appearance: none;
					appearance: none;
					font-weight: 200;
					@include transition();
					border: 0;
					border-bottom: 2px solid $color-black;
					&:focus {
						border-bottom: 2px solid $color-primary;
						outline: 0;
				}
			}
			input[type=submit] {
					margin-top: 20px;
			}
		}
		.modal-footer {
			display: block;
			margin-top: 10px;

			.contact-submit-cont {
				width: 100%;
				margin: 0;

				.contact-submit-text {
					@include font-size(12px);
					color: #999;
					text-align: left;
					margin-bottom: 5px;

					@include media(sm) {
						margin-bottom: 10px;
					}

					@include media(md) {
						@include font-size(13px);
					}

					@include media(lg) {
						margin-bottom: 18px;
					}
				}

				input[type="submit"] {

			}
		}
	}
}

#modal-login {
		.modal-title {
				padding-top: 20px;
		}
		.contact-submit-cont {
				margin-top: 30px;
				input {
					margin-bottom: 20px;
				}
		}
    input[type=text],
    input[type=password] {
        background-color: $color-white;
        width: 100%;
        padding: 8px 10px;
        height: 38px;
        color: $color-black;
        margin-bottom: 15px;
        border-radius: 0;
        position: relative;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-weight: 200;
        @include transition();
        border: 0;
        border-bottom: 2px solid $color-black;
        &:focus {
          border-bottom: 2px solid $color-primary;
          outline: 0;
        }
    }
}

#modal-languages {
		.modal-content {
				border: 0 !important;
				background-color: transparent !important;
		}
		.modal-header {
				background-color: transparent !important;
				color: #fff;
				padding: 10px 0;
				.modal-title {
						@include media(lg) {
								@include font-size(24px);
						}
				}
				.close {
						border: 1px solid #fff !important;
						padding: 7px;
						border-radius: 50%;
						@include transition;
						@include media(lg) {
								right: 0;
						}
						&:after {
								color: #fff !important;
								@include font-size(10px);
								@include transition;
						}
				}
		}
		.modal-body {
				padding: 0 !important;
		}
}
