$selectric-main-color:      #7ab800 !default;                                              // Color used in border
$selectric-secondary-color: #e0e0e0 !default;                                              // Color used in button
$selectric-text-color:      #636c71 !default;                                              // Color used in label
$selectric-bg-color:        #000 !default;                                           // Background color
$selectric-btn-bg-color:    #fff !default;                                           // Button background color
$selectric-height:          38px !default;                                              // Outer height
$selectric-spacing:         10px !default;                                              // Label left padding
$selectric-border-width:    1px !default;                                               // Outer border width
$selectric-border-radius:   0px !default;                                               // Border radius
$selectric-inner-height:    $selectric-height - ($selectric-border-width * 2) !default; // Inner height
$selectric-font-size:       15px !default;                                              // Font size
$selectric-border-color:    #000 !default;
$selectric-gray-color:      #fff !default;

.selectric-wrapper {
  position: relative;
  cursor: pointer;
  margin-bottom: 15px;
}

.selectric-responsive {
  width: 100%;
}

.selectric {
  border: 0;
	border-bottom: 2px solid #000;
  border-radius: 0;
  background: #fff;
  position: relative;
  overflow: hidden;
  .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 $selectric-inner-height 0 $selectric-spacing;
    font-size: $selectric-font-size;
    line-height: $selectric-inner-height;
    color: $selectric-text-color;
    height: $selectric-inner-height;
    user-select: none;
  }

  .button {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: $selectric-inner-height;
    height: $selectric-inner-height;
    line-height: $selectric-inner-height;
    background-color: $selectric-btn-bg-color;
    color: #000;
    text-align: center;
    font: 0/0 a;
    *font: 20px/#{$selectric-inner-height} Lucida Sans Unicode, Arial Unicode MS, Arial;
    transition: all ease 200ms;

    &:after {
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 30px;
      content: "\e91f";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(90deg);
      color: $selectric-main-color;
			font-weight: 600;
    }
  }
}

.selectric-focus .selectric {
  	border-bottom: 2px solid $selectric-main-color;
}

.selectric-hover .selectric {

}

.selectric-open {
  z-index: 9999;

  .selectric {
    border-bottom: 2px solid $selectric-main-color;
    .button {
      color: darken($selectric-main-color, 10%);
        -ms-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
  }

  .selectric-items {
    display: block;
  }
}

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;

  select {
    position: absolute;
    left: -100%;
  }

  &.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;

    select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0;
    }
  }
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: $selectric-main-color;
  border: 1px solid $selectric-border-color;
  border-top: 0;
  z-index: -1;
  box-shadow: 0 0 10px -6px;

  .selectric-scroll {
    height: 100%;
    overflow: auto;
  }

  .selectric-above & {
    top: auto;
    bottom: 100%;
  }

  ul, li {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: $selectric-font-size;
    line-height: 20px;
    min-height: 20px;
  }

  li {
    display: block;
    padding: 10px;
    color: $selectric-text-color;
    background: #fff;
    cursor: pointer;
    transition: all ease 200ms;

    &.selected {
      background: $selectric-main-color;
			color: #fff;
    }
    &:hover {
      background: $selectric-main-color;
      color: #fff;
    }
  }

  .disabled {
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default !important;
    background: none !important;
    color: #666 !important;
    user-select: none;
  }

  .selectric-group {
    .selectric-group-label {
      font-weight: bold;
      padding-left: 10px;
      cursor: default;
      user-select: none;
      background: none;
      color: #444;
    }

    &.disabled li {
      filter: alpha(opacity=100);
      opacity: 1;
    }

    li {
      padding-left: 25px;
    }
  }
}
