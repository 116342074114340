@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/afi.scss";

.pux-container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    @include transition;
		max-width: 100%;
		//height: 100%;
    @include media(xl) {
          max-width: 1320px;
					padding-left: 20px;
					padding-right: 20px;
    }
    &:after{
        display: block;
        content: "";
        clear: both;
    }
}

.col-xs-five-grid {
  @include media(xs, xs) {
      @include flex(0 0 20%);
      max-width: 20%;
  }
}

.col-sm-five-grid {
  @include media(sm, sm) {
      @include flex(0 0 20%);
      max-width: 20%;
  }
}

.col-md-five-grid {
  @include media(md, md) {
      @include flex(0 0 20%);
      max-width: 20%;
  }
}

.col-lg-five-grid {
  @include media(lg, lg) {
      @include flex(0 0 20%);
      max-width: 20%;
  }
}

.col-xl-five-grid {
  @include media(xl, xl) {
      @include flex(0 0 20%);
      max-width: 20%;
  }
}

.flex-as-end{
    @include align-self(flex-end);
}

.flex-box{
    @include flex-block;
}

.jc-space{
    @include justify-content(space-between);
}

.ai-center{
    @include align-items(center);
}

.full-container{
    max-width: $max-page-width;
    margin-left: auto;
    margin-right: auto;
    background-color: $color-white;
		@include calc("min-height", "100vh - 232px");
    &:after{
        display: block;
        content: "";
        clear: both;
    }
}

.header-helper {
		background-color: $color-base;
		padding-top: $header-w-small;
		@include media(lg) {
				padding-top: $header-w-large;
		}
}


header{
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    right: 0;
    margin: 0 auto;
    z-index: 999;
		max-width: $max-page-width;
		background-color: $color-primary;
		border-bottom: 2px solid #fff;
    width: 100%;
		@include media(lg) {
				border-bottom: 4px solid #fff;
		}
    .logo{
        padding:8px 13px 8px 0;
        a{
            display:inline-block;
        }
        img{
            height:30px;
						@include media(lg) {
								height:42px;
                width: 130px;
						}
        }
        span,
        h1{
            display:none;
        }
    }
    .main-menu{
        width:100%;
        position:absolute;
        top:50px;
        left:0;
        display:none;
        background: $color-primary;
        @include calc("max-height", "100vh - 50px");
        overflow:auto;
        @include media(lg) {
            width: auto;
            flex: 1;
        }
        ul{
            margin:0;
            padding:0;
            list-style:none;
            @include flex-block;
            @include flex-direction(column);
						@include media(lg){
								padding-right: 15px;
								padding-left: 15px;
								@include justify-content(space-between);
						}
						@include media(xl){
								padding-right: 50px;
								padding-left: 30px;
						}
						@include media(xxl){
								padding-right: 80px;
								padding-left: 50px;
						}
            & > li{
                position:relative;
								@include media(lg) {
										border-bottom: 4px solid $color-white;
								}
								&:not(:last-of-type) {
										border-bottom:1px solid #fff;
								}
                & > a{
                    cursor: pointer;
                    display: block;
                    @include font-size(16px);
                    line-height: 1;
                    position:relative;
                    padding: 18px 15px;
                    color: $color-white;
                    text-decoration: none;
                    font-weight:300;
										letter-spacing: .04rem;
                    & > span{
                        display:inline-block;
                        position:relative;
												@include font-size(16px);
                        &:after{
                            display: block;
                            content: "";
                            height: 2px;
                            left:0;
                            top: 100%;
                            margin-top: 4px;
                            width: 100%;
                            background: $color-link;
                            @include opacity(0);
                            visibility: hidden;
                            @include animate("opacity, visibility");
                            position:absolute;
                        }
                    }
                    &:focus,
                    &:hover{
                        @include media(lg){
                            span{
                                &:after{
                                    @include opacity(1);
                                    visibility: visible;
                                }
                            }
                        }
                    }
                    @include media(lg){
                        @include font-size(14px);
												padding: 32px 7px;
                    }
                    @include media(xl){
                        padding: 32px 15px;

                    }
										@include media(xxl){
												padding: 32px 20px;

										}
                }
                &.Highlighted{
                    & > a{
                        span{
                            &:after{
                                @include opacity(1);
                                visibility: visible;
                            }
                        }
                    }
                }
                @include media(lg){
                    border:none !important;
                    position:static;
                }
                &:hover{
                    .submenu{
                        @include opacity(1);
                        visibility: visible;
                    }
                }
                .menu-mobile{
                    position:absolute;
                    right:0;
                    top:0;
                    width:65px;
                    height:53px;
                    text-align:center;
                    background:none;
										color: #fff;
                    &:before{
                        content:"";
                        width:26px;
                        height:2px;
                        background:#fff;
                        display:block;
                        position:absolute;
                        left: 50%;
                        top: 50%;
                        margin-top: -1px;
                        margin-left: -13px;
                    }
                    &:after{
                        content:"";
                        width:2px;
                        height:26px;
                        background:#fff;
                        display:block;
                        position:absolute;
                        left: 50%;
                        top: 50%;
                        margin-top: -13px;
                        margin-left: -1px;
                        @include opacity(1);
                        visibility:visible;
                        @include transition;
                    }
                    &.open{
                        &:after{
                            @include rotate(90deg);
                        }
                    }
                    @include media(lg){
                        display:none;
                    }
                }
             }
            @include media(lg){
               @include flex-direction(row);
               @include align-items(center);
            }
        }
        @include media(lg){
            position:relative;
            top:0;
            overflow:visible;
            display:block !important;
        }
    }
    .header{
        @include flex-block;
        @include align-items(center);
        @include justify-content(space-between);
				background: $color-primary;
				height: $header-w-small;
        &-search{
            position:absolute;
            width:100%;
            background:$color-black;
            padding:0 10px;
            left:0;
            top:50px;
            height:0;
            overflow:hidden;
            @include transition(300ms);
            @include media(lg){
                position:static;
                max-width:130px;
                overflow:visible;
                height:auto !important;
                padding:0;
            }
            label{
                display:none;
            }
            input[type="text"]{
                @include border-radius(0px);
                width:100%;
                height:32px;
                padding:0 35px 0 10px;
                border:none;
                outline:0;
                @include box-shadow(none);
                &:focus{
                    border:none;
                    outline:0;
                    @include box-shadow(none);
                }
            }
            input[type="submit"]{
                position:absolute;
                bottom:0;
                right:0;
                height:32px;
                width:32px;
                z-index:22;
                @include border-radius(0px);
                background:transparent;
                font-size:0;
                @include opacity(0);
                &:focus{
                    border:none;
                    outline:0;
                    @include box-shadow(none);
                }
            }
						.btn-search{
								position:absolute;
								top:10px;
								right:0;
								font-size:0;
								width:30px;
								text-align:center;
								&:before{
										content:"\e909";
										@include font-size(20px);
										line-height:32px;
										color: $color-black;
								}
								@include media(lg) {
										top:0px;
								}
						}
						/*
            .searchBox{
                position:relative;
                &:before{
                    content:"\e909";
                    @include font-size(20px);
                    line-height:32px;
                    position:absolute;
                    bottom:0;
                    right:5px;
                }
            }*/
            &-inner{
                position:relative;
                padding:10px 0 15px 0;
                @include media(lg){
                    padding:0;
                }
            }
        }
        &-login{
            position:absolute;
            background:$color-white;
            top: $header-w-small;
            right:0;
            width:100%;
            display:none;
						@include media(lg) {
								top: $header-w-large;
						}
            &-inner{
                position:relative;
                padding:10px;
								border: 2px solid $color-black;
								border-top: 0;
								input[type=text],
								input[type=password] {
										background-color: $color-white;
						        width: 100%;
						        padding: 8px 10px;
						        height: 38px;
						        color: $color-black;
						        margin-bottom: 15px;
						        border-radius: 0;
						        position: relative;
						        -webkit-appearance: none;
						        -moz-appearance: none;
						        appearance: none;
						        font-weight: 200;
						        @include transition();
										border: 0;
										border-bottom: 2px solid $color-black;
						        &:focus {
						          border-bottom: 2px solid $color-primary;
						          outline: 0;
						        }
								}
                a {
                  cursor: pointer;
                }
								.logon-remember-me-checkbox {
										display: none;
								}
								.login-btn {
										text-align: right;
								}
								a {
										margin-top: 10px;
										display: inline-block;
										@include media(lg) {
												@include font-size(16px);
										}
								}
								.logon-panel-password-retrieval > div:first-of-type{
										margin-top: 10px;
								}
								.logon-password-retrieval-result {
										display: block;
										font-family: 'Zirkel-Bold';
										margin-top: 10px;
								}
            }
            @include media(lg){
                right:100px;
                width:360px;
            }
						.error-label {
								display: inline-block;
								margin-bottom: 20px;
								font-family: 'Zirkel-Bold';
						}
						.form-control-error {
								font-family: 'Zirkel-Bold';
						}
        }
        &-nav{
            @include flex-block;
            @include align-items(center);
            a{
                width:32px;
                height:32px;
                text-align:center;
                display: block;
                color: $color-white !important;
                text-decoration: none;
                font-weight:400;
                margin: 0 0 0 10px;
                @include media(lg){
                    width:auto;
                    height:auto;
                }
            }
            &-search{
                font-size:0;
                &:before{
                    content:"\e909";
                    @include font-size(20px);
                    line-height:32px;
										color: $color-white;
                    @include media(lg){
                        display:none;
                    }
                }
            }
            &-login{
                margin: 0 2px;
                font-size:0;
                position: relative;
                @include animate("background-color, color");
								color: $color-white;
                &:focus,
                &:hover{
                    @include media(lg){
                        color: $color-black !important;
                        background: $color-white;
                    }
                }
                &:before{
                    content:"\e90f";
                    @include font-size(20px);
                    line-height:32px;
										color: $color-white;
                    @include media(lg){
                        display:none;
                    }
                }
                @include media(lg){
                    padding: 7px;
                    margin: 0 8px;
                    @include font-size(14px);
                    width: 84px !important;
                    text-align: center;
                    line-height:1;
										border: 2px solid $color-white;
										color: $color-white;
                    border: 2px solid $color-white;
										margin-right: 10px;
                }
								@include media(xxl) {
										margin-right: 30px !important;
								}
            }
						&-logout {
								@include media(xxs, md) {
										width: 50px !important;
										margin-left: 0 !important;
										padding-top: 3px;
								}
						}
            &-language{
                font-size:0;
                margin: 0 2px;
								color: $color-white;
								margin-left: 10px;
								display: none;
                &:after{
                    content:"\e905";
                    @include font-size(20px);
                    line-height:32px;
										color: $color-white;
                    @include media(lg){
                        margin:0 0 0 5px;
                        vertical-align:-4px;
                        line-height:normal;
                    }
                }
                @include media(lg){
										width: 100px !important;
										display: block;
                    margin: 0 8px;
                    @include font-size(16px);
                    padding: 0px 0 0 20px;
										text-align: right;
                }
            }
            &-menu{
                position:relative;
                span{
                    width:26px;
                    position:absolute;
                    top:50%;
                    margin-top:-1px;
                    background:$color-white;
                    height:2px;
                    left:50%;
                    margin-left:-13px;
                    @include transition;
                    &:before,
                    &:after{
                        content:"";
                        width:26px;
                        height:2px;
                        position:absolute;
                        display:block;
                        background:$color-white;
                        @include transition;
                    }
                    &:before{
                        top:-8px;
                    }
                    &:after{
                        bottom:-8px;
                    }
                }
                @include media(lg){
                    display:none !important;
                }
                &.open{
                    span{
                        background:transparent;
                        &:before{
                            @include rotate(45deg);
                            top:0;
                        }
                        &:after{
                            @include rotate(-45deg);
                            top:0;
                        }

                    }
                }
            }
        }
        @include media(lg){
            @include justify-content(flex-start);
						height: $header-w-large;
        }
    }
    .submenu{
        font-size: 0;
        white-space: nowrap;
        background: $color-white;
        z-index: 5;
        display:none;
        @include flex-direction(column);
				@include media(lg) {
						background: $color-black;
						border-top: 4px solid $color-white;
				}
        ul{
            display:block;
            width: 100%;
            min-width:187px;
            white-space: normal;
            @include font-size-with-line(14px);
            border-top:1px solid $color-black;
            li{
                display:block;
                border-bottom: 1px solid $color-black;
                a{
                    display: block;
                    padding: 9px 15px;
                    position: relative;
                    font-weight:400;
										color: $color-black;
                    &:hover{
                        color: $color-link-hover;
                    }
                    @include media(lg){
                        padding: 9px 0;
												color: $color-white;
                        border-bottom: 1px solid $color-white;
                    }
                }
                span{
                    display: block;
                    font-weight:600;
                    padding: 9px 15px;
                    background: $color-white;
                    color:$color-primary;
                    border-bottom: 1px solid $color-white;
                    @include media(lg){
                        background:$color-black;
                        color:#000;
                        padding: 9px 0;
                    }
                }
                &:last-child{
                    border:none;
                    @include media(lg){
                        //border-bottom: 1px solid $color-base;
                    }
                }
                ul{
                    margin:0;
                    padding:0;
                    width:auto;
                    min-width:10px;
                }
            }
            @include media(lg){
                width: 187px;
                padding: 0 15px;
                border:none;
            }
        }
        @include media(lg){
            position:absolute;
            @include flex-direction(row);
            padding: 5px 7px 20px;
            @include opacity(0);
            visibility: hidden;
            @include animate("opacity, visibility");
            @include flex-block;
        }
    }

}

.loader {
	display: none;
  @include opacity(0);
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #ffffff;
  background: -moz-linear-gradient(left, $color-link 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, $color-link 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, $color-link 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, $color-link 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, $color-link 10%, rgba(255, 255, 255, 0) 42%);
  position: absolute;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
z-index: 33;
  left: 50%;
  margin-left: -60px;
}
.loader:before {
  width: 50%;
  height: 50%;
  background: $color-link;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  background: $color-white;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.quick-search{
    &.opacity{
        .loader{
						display: inline-block;
            @include opacity(1);
        }
        .quick-search-inner{
            @include opacity(0);
						max-height: 293px;
						overflow: hidden;
						header {
							display: none;
						}
        }
    }
}

.quick-search{
		position: fixed;
		background: #e0e0e0;
		top: 105px;
		left: 0;
		width: 100%;
    background: $color-white;
    visibility:hidden;
    @include opacity(0);
    @include transition;
		border: 1px solid $color-black;
		@include media(lg) {
				 position:absolute;
				 top: 55px;
				 width:500px;
				 right:-170px;
				 left: auto;
		}
    &-inner{
        padding-bottom: 10px;
        min-height: 293px;
        @include opacity(1);
        @include transition;
    }
    .header-search-item{
        @include flex-block;
        @include align-items(center);
        color: $color-black;
        text-decoration:none !important;
        border-bottom:1px solid $color-black;
				@include transition;
        &-image{
            position:relative;
            width:130px;
            min-width:130px;
            height:90px;
            background:#fff;
            text-align:center;
						@include flex-block;
						@include justify-content(center);
						@include align-items(center);
						overflow: hidden;
        }
        &-name{
            font-weight:bold;
            padding:0 15px;
            width:100%;
            @include font-size(18px);
        }
        &-arrow{
            width:30px;
            height:30px;
            min-width:30px;
            background:transparent;
            color: $color-black;
            margin-right:10px;
            text-align:center;
            @include border-radius(50%);
            @include transition;
						@include animate("background, border-color, color");
            &:before{
                content:"\e91f";
                line-height:28px;
                @include font-size(24px);
                margin-left: -2px;
            }
        }
        &:focus,
        &:hover{
            background:$color-link;
						color: $color-white;
            .header-search-item-arrow {
                color: $color-white;
            }
        }
    }
    &.open{
        visibility:visible;
        @include opacity(1);
    }
		.btn-search-more {
				color: $color-black;
				font-family: 'Zirkel-Bold';
				&:hover,
				&:focus {
						color: $color-white !important;
						background-color: $color-link;
						border-color: $color-link;
				}
		}
}

.quick-search-btn{
    padding:10px 10px 20px 10px;
}


.submenu-full{
    .submenu{
        left:0;
    }
}

footer{
    max-width:1290px;
    margin:auto;
    background: $color-primary;
    color:#fff;
    padding: 20px 0;
		margin-top: 0;
    @include font-size-with-line(14px);
    .nwl-title{
        margin-bottom: 20px;
    }
    .nwl-box{
        label{
            display:none;
        }
        .form-horizontal{
            @include flex-block;
            @include align-items(flex-start);
            @include flex-direction(column);
            @include media(md){
                @include flex-direction(row);
            }
            .form-group{
                width:100%;
                @include media(md){
                    width:auto;
                }
            }
        }
        input[type="text"]{
            width:100%;
            margin-bottom: 0;
            padding: 0 5px;
            height: 26px;
            line-height: 24px;
            font-size: 14px;
            color: $color-white;
            background: transparent;
            border: 1px solid $color-white;
            @include border-radius(0);
            @include animate(border-color);

            &:focus{
                border-color: $color-white;
            }

            &:-moz-placeholder {
               color: #fff;
            }

            &::-moz-placeholder {
                     color: #fff;
            }

            &::-webkit-input-placeholder {
                color: #fff;
            }

            &:-ms-input-placeholder {
               color: #fff;
            }
            @include media(md){
                width: 200px;
            }
        }
        input[type="submit"]{
            cursor:pointer;
            display: block;
            float: right;
            width: 100%;
            margin-left: 10px;
            font-size: 14px;
            height: 26px;
            outline: none;
            text-align: center;
            text-decoration: none;
						padding: 0 !important;
            color: #7a7a7a;
            background: transparent;
            border: 1px solid #7a7a7a;
            font-weight:bold;
						font-family: 'Zirkel-Bold';
            @include animate("background, border-color, color");

            &:hover, &.active{
                    border-color: #7a7a7a;
                    color: $color-white;
										background: #7a7a7a
            }
            @include media(md){
                width: 85px;
            }
        }
    }
    .nwl-bottom{
        @include flex-block;
        @include flex-direction(column);
        .social{
            font-size:0;
            .ico-social{
                display:inline-block;
                //background:#fff;
                @include border-radius(50%);
                margin:5px;
                height:27px;
                width:27px;
                text-decoration:none !important;
                position:relative;
                &:before{
                    color:#fff;
                    @include animate(color);
                    @include font-size(27px);
                    position:absolute;
                    top:0;
                    left:0;
                }
                &:focus,
                &:hover{
                    &:before{
                        color:$color-link;
                    }
                }
                &:first-child{
                    margin:5px 5px 5px 0;
                    @include media(md){
                        margin:5px;
                    }
                }
                @each $name, $icon-number in $share-icon-names {
                    &.s-#{$name} {
                        &:before {
                            content: "#{$icon-number}";
                        }
                    }
                }
            }
        }
        .copyright{
            padding-bottom:10px;
            @include font-size-with-line(10px);
            @include media(md){
                padding-right: 10px;
            }
            @include media(lg){
                padding-right: 85px;
                padding-bottom:0;
            }
        }
        @include media(md){
            @include align-items(center);
            @include flex-direction(row);
        }
    }
    ul{
        margin:0;
        padding:0;
        list-style:none;
        li{
            display: inline-block;
            vertical-align: top;
            margin:0 5px 0 -5px;
            @include font-size-with-line(10px);
            a{
               display: block;
                padding:5px;
                color: $color-white;
                text-decoration: none;

                &:hover{
                    color: $color-white;
                    text-decoration: underline;
                }
            }
        }
    }
}

.bottom-logo {
		img {
				width: 70px;
				height: auto;
				@include media(lg) {
						width: 120px;
				}
		}
		&-text {
			margin-top: 5px;
			@include font-size(8px);
			font-weight: 300;
			line-height: normal;
			margin-bottom: 20px;
			@include media(lg) {
					@include font-size(10px);
			}
		}
}

.block-50 {
		margin: 40px 0;
		@include media(md) {
				margin: 50px 0;
		}
}

.block-60 {
		margin: 40px 0;
		@include media(md) {
				margin: 60px 0;
		}
}

.block-large {
		margin-top: 30px;
		margin-bottom: 0px;
		@include media(md) {
				margin-top: 40px;
		}
		@include media(lg) {
				margin-top: 60px;
		}
		@include media(xxl) {
				margin-top: 80px;
		}
		&-wth-title {
				margin-top: 20px;
				@include media(md) {
						margin-top: 20px;
				}
				@include media(lg) {
						margin-top: 30px;
				}
				@include media(xxl) {
						margin-top: 40px;
				}
				& > .row > div:first-of-type {
						padding-top: 20px;
						@include media(md) {
								padding-top: 50px;
						}
						@include media(lg) {
								padding-top: 52px;
						}
						@include media(xl) {
								padding-top: 54px;
						}
						@include media(xxl) {
								padding-top: 54px;
						}
				}
		}
}

.content-wrapper {
		ul {
				margin:0;
				padding:0;
				list-style:none;
				li{
						@include font-size(16px);
						margin:0 0 10px 0;
						padding:0 0 0 15px;
						position:relative;
						&:before{
								content:"";
								width:5px;
								height:5px;
								background: $color-black;
								position:absolute;
								top:9px;
								left:5px;
								@include border-radius(50%);
						}
				}
		}
}

.columns-borders {
		& > .row > div:first-of-type {
				& > div:first-child {
						@include media(md) {
								border-top: 1px solid $color-primary;
						}
				}
		}
		& > .row > div:last-of-type {
				.content-wrapper {
						$offset: -30px;
						$offset-lg: -45px;

						height: 100%;
						position: relative;
						&:before {
								content: "";
								display: block;
								position: relative;
								top: 0;
								left: 0;
								width: 100%;
								height: 1px;
								background-color: $color-primary;
						}
						&:after {
								@include media(md) {
										content: "";
										position: absolute;
										display: block;
										top: 0;
										@include calc("left",  "(" $offset "/2) - 1px/2");
										width: 1px;
										@include calc("height", "100% - 40px");
										background-color: $color-primary;
										@include media(md) {
												@include calc("height", "100% - 80px");
										}
										@include media(lg) {
												@include calc("left",  "(" $offset-lg "/2) - 1px/2");
												@include calc("height", "100% - 80px");
										}
								}
						}
				}
		}
		&.text-page {
				.content-wrapper {
						margin-bottom: 30px;
						@include media(md) {
								margin-bottom: 40px;
						}
						@include media(lg) {
								margin-bottom: 60px;
						}
						&:after {
								height: 100% !important;
						}
				}
		}
}

.product-page {
		padding-bottom: 30px;
		@include media(md) {
				padding-top: 70px;
				padding-bottom: 50px;
		}
		& > .row > div:first-of-type {
				margin-top: 20px;
				@include media(md) {
						margin-top: 0;
						&:before {
								content: "";
								display: block;
								width: 100%;
								height: 1px;
								background-color: $color-primary;
						}
				}
		}
		& > .row > div:last-of-type {
				.content-wrapper {
						$offset: -30px;
						$offset-lg: -45px;

						height: 100%;
						position: relative;
						&:before {
								@include media(md) {
										content: "";
										display: block;
										position: relative;
										top: 0;
										left: 0;
										width: 100%;
										height: 1px;
										background-color: $color-primary;
								}
						}
						&:after {
								@include media(md) {
										content: "";
										position: absolute;
										display: block;
										top: 0;
										@include calc("left",  "(" $offset "/2) - 1px/2");
										width: 1px;
										@include calc("height", "100%");
										background-color: $color-primary;
										@include media(lg) {
												@include calc("left",  "(" $offset-lg "/2) - 1px/2");
												@include calc("height", "100%");
										}
								}
						}
						h1 {
								padding-bottom: 10px !important;
								margin: 0 !important;
						}
				}
		}
		.product-preview {
				@include media(md) {
						margin-top: 20px;
				}
		}
}


.style-red {
		color: $color-secondary;
}

.items-container {
  @include flex-block();
  @include flex-wrap(wrap);
}

.base-padding {
		padding-left: 15px;
		padding-right: 15px;
		@include media(xl) {
				padding-left: 20px;
				padding-right: 20px;
		}
}

.mt-20 {
		margin-top: 20px;
}

.base-margin-top {
		margin-top: 20px;
		@include media(xxl) {
				margin-top: 30px;
		}
}

.full-height {
		height: 100%;
}

.block-search {
		padding-top: 30px;
		padding-bottom: 30px;
		@include media(md) {
				padding-top: 50px;
				padding-bottom: 50px;
		}
}

.map-area-image{
    position:relative;
    .language-area-image{
        position:absolute;
        left:0;
        top:0;
        z-index:33;
    }
		img {
				max-width: 100%;
		}
}

.modal-language-country{
    color:#fff !important;
    display:inline-block;
    &:focus,
    &.hover{
        color:$color-primary !important;
    }
}
